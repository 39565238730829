<template>
  <div class="home page">
    <div class="gradient"></div>
        <header>

            <div class="nav-container">
                <router-link to="/"><img src="@/assets/img/logo.png" alt="logo" class="logo"></router-link>
                <Nav/>
            </div>
            <div>
                
            </div>
        </header>
        <section class="content" style="overflow:hidden;">

            <section class="article" v-if="post && !error">
                <img :src="post.img" alt="Article image" loading="lazy" class="article-img" v-if="post.img != ''">
                <div class="section">
                    <div el="list md">
                        <div el="list xxsm">
                            <h4 el>{{post.title}}</h4>
                            <p el="italic">By: {{post.author.name}} | Updated: {{formatDate(post.updatedOn)}}</p>
                        </div>
                        <ViewPost :data="post.content" />
                        <!-- <router-link to="/community/request" class="button">Make a Request</router-link> -->
                    </div>
                </div>

            </section>
            <section v-else>
                <div class="section">
                    <div el="list xsm">
                        <h4 el>Article Not Found!</h4>
                        <p el>We were unable to locate this article. This means it never existed or was deleted.</p>
                        <!-- <router-link to="/community/request" class="button">Make a Request</router-link> -->
                    </div>
                </div>

            </section>

        </section>
        <Footer />
  </div>

</template>

<script>
// @ is an alias to /src
import {db} from '../firebase'
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import ViewPost from '@/components/ViewPost.vue'

export default {
  name: 'Article',
  components: {
      Nav,
      Footer,
      ViewPost
  },
  data(){
    return {
        post:null,
        error: false,
    }
  },
  methods:{
      formatDate(date){
          let d = new Date(date);
          return d.toLocaleString('en-US');
      }
  },
  watch: {
    // $route(to, from) {
    $route(to) {
      // react to route changes...
      console.log(to);
    }
  },
  computed:{
      postID(){
          return this.$route.params.id // 'admin'
      }
  },
  mounted(){
      var x = this;

      let docRef = db.collection("posts").doc(this.postID);
        docRef.get().then((doc) => {
            if (doc.exists) {
                let data = doc.data();
                data.id = doc.id;
                x.post = data;
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
                x.error = true;
            }
        }).catch((error) => {
            x.error = true;
            alert("Error getting document:", error);
        });
  }
}
</script>
<style>
.article-img{
    width: 100%;
    height: 400px;
    background-color: #f1f1f1;
    object-fit: cover;
    object-position: center;
}
header{
    min-height: 280px;
    background-color: rgba(0, 0, 0, 0.6);
}
@media(max-width:600px){
    td{
        font-size:14px;
    }
}
</style>